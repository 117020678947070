export var formData = {
  name: '', // 公司名称
  status: null, // 合同状态
  medium: 0, // 合同介质
  mySubject: '', // 我方主体
  supplierIsSignature: '', // 我方是否签章
  contractEffective: '', // 合同年限有效期
  file: [], // 合同文件
  strStartDate: '', // 合同开始时间
  strEndDate: '' // 合同结束时间

}

export var rules = {
  name: [
    { required: true, message: '输入合同名称', trigger: 'blur' }
  ],
  medium: [
    { required: true, message: '请选择合同介质', trigger: 'change' }
  ],
  signature: [
    { required: true, message: '请选择签章方式', trigger: 'change' }
  ],
  contractEffective: [
    { required: true, message: '请输入合同有效期,例如:1年', trigger: 'blur' }
  ],
  file: [
    { required: true, message: '请上传合同文件', trigger: 'blur' }
  ],
  supplierIsSignature: [
    { required: true, message: '请选择是否签章', trigger: 'change' }
  ],
  strStartDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'change' }
  ],
  strEndDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'change' }
  ]
}
