<template>
    <div class="mainform">
      <div class="mainHeader">
        <span v-if="!form.number">起草合同</span>
        <span v-if="form.number">合同{{form.number}}</span>
        <span @click="back" class="back">返回></span>
      </div>
      <div class="form">
        <el-form class="content bascform" ref="form"  :rules="rules" :model="form"  label-width="130px">
          <div class="col4">
            <el-form-item label="合同名称" prop="name">
              <el-input  v-model="form.name"  ></el-input>
            </el-form-item>
          </div>
          <div class="col4">
            <el-form-item label="我方签约主体" prop="subject" >
              <span class="onlyText">{{form.subject}}</span>
            </el-form-item>
          </div>
<!--     <div class="col4">
            <el-form-item label="合同有效期(年)" prop="contractEffective">
              <el-input  v-model="form.contractEffective" placeholder="XXX年"></el-input>
            </el-form-item>
          </div> -->
          <div  class="col4">
            <el-form-item label="合同生效期" required>
              <el-col :span="11">
                <el-form-item prop="strStartDate">
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.strStartDate" @change="timeForm" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="strEndDate">
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.strEndDate" @change="timeFormEnd" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
          </div>
          <div class="col4">
            <el-form-item label="合同介质"  prop="medium">
                <el-radio-group v-model="form.medium">
                  <el-radio :label="0">纸质合同</el-radio>
                  <el-radio :label="1">电子合同</el-radio>
                </el-radio-group>
              </el-form-item>
          </div>
          <div class="col4">
            <el-form-item label="合同文件" v-model="form.file" prop="file">
              <el-upload
                  :action="actionURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :headers="tokenHeader"
                  :on-success="handleSuccess"
                  multiple
                  :on-exceed="handleExceed"
                  :file-list="form.file"
                >
                <el-button size="small" type="primary" plain>上传合同文件</el-button>
              </el-upload>
            </el-form-item>
          </div>
          <div class="col4">
            <el-form-item label="合同附件" v-model="form.file">
              <el-upload
                  :action="actionURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :headers="tokenHeader"
                  :on-success="handleSuccess2"
                  multiple
                  :on-exceed="handleExceed"
                  :file-list="file2"
                >
                <el-button size="small" type="primary" plain>上传合同附件</el-button>
              </el-upload>
            </el-form-item>
          </div>
          <div class="col4">
            <el-form-item label="是否签章" prop="supplierIsSignature">
              <el-radio-group v-model="form.supplierIsSignature">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="action" v-if="form.status ===null||form.status ===9||form.status ===7">
            <el-button v-if="form.status !==7" class="submit" type="primary" size="medium" @click="saveBtn">暂存草稿</el-button>
            <el-button class="submit" type="primary" size="medium" @click="submitBtn" >提交</el-button>
          </div>
        </el-form>
      </div>
    </div>

</template>

<script>
import { baseHost } from '@/assets/js/PublicData.js'
import { MIME } from '@/assets/js/mime.js'
import { exportForm, request, deepClone } from '@/assets/js/http.js'
import { formData, rules } from './js/supplierDraft'
import dayjs from 'dayjs'
export default {
  name: 'SupplierDraft',
  data: function () {
    return {
      form: deepClone(formData),
      rules: rules,
      fieldShow: {},
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      erpCode: '',
      formDisabled: true,
      file2: []
    }
  },
  created () {
    var erpCode = this.$store.state.loginInfo.erpCode
    if (erpCode) {
      request('/api/supplier/basicData/getByErpCode/' + erpCode, 'GET').then((response) => {
        if (response.code === '200') {
          this.form.subject = response.data.name
          this.form.subjectCode = response.data.erpCode
        }
      })
    }
    this.contractDetails()
  },
  watch: {
    '$store.state.loginInfo': function () {
      var erpCode = this.$store.state.loginInfo.erpCode
      if (erpCode) {
        request('/api/supplier/basicData/getByErpCode/' + erpCode, 'GET').then((response) => {
          if (response.code === '200') {
            this.form.subject = response.data.name
            this.form.subjectCode = response.data.erpCode
          }
          this.contractDetails()
        })
      }
    }
  },
  methods: {
    contractDetails () {
      const id = this.$route.query.id
      if (id) {
        request('/api/pact/contract/queryOne?id=' + id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            this.form.strStartDate = this.form.startDate
            this.form.strEndDate = this.form.endDate
          }
        })
      }
    },
    saveBtn () { // 暂存  状态为草稿
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      const obj = {
        ...this.form,
        status: 9
      }
      obj.file = this.form.file.concat(this.file2)
      request('/api/pact/contract/saveSupplierOperate', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({
            path: '/ContractOrder/SupplierContractList'
          })
        }
      })
    },
    submitBtn () { // 提交
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      const obj = {
        ...this.form,
        status: 7 // 待确认
      }
      obj.file = this.form.file.concat(this.file2)
      let url = ''
      if (this.form.status !== null) {
        url = '/api/pact/contract/approvalUpdate'
      } else {
        url = '/api/pact/contract/saveSupplierOperate'
      }
      request(url, 'POST', obj).then((response) => {
        if (response.code === '200') {
          // this.form = response.data
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({
            path: '/ContractOrder/SupplierContractList'
          })
        }
      })
    },
    // 选择合同生效日期转换格式
    timeForm () {
      if (this.form.strStartDate) {
        this.form.strStartDate = dayjs(this.form.strStartDate).format('YYYY-MM-DD')
      } else {
        this.form.strStartDate = ''
      }
    },
    timeFormEnd () {
      if (this.form.strEndDate > new Date(this.form.strStartDate)) {
        if (this.form.strEndDate) {
          this.form.strEndDate = dayjs(this.form.strEndDate).format('YYYY-MM-DD')
        }
      } else {
        this.$message({
          message: '结束日期不能小于开始日期',
          type: 'error'
        })
        this.form.strEndDate = ''
      }
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.file.length; i++) {
        if (this.form.file[i].id === file.id) {
          this.form.file.splice(i, 1)
        }
      }
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // handleSuccess (response, file, fileList) {
    //   if (response.code === '200') {
    //     response.data.forEach(element => {
    //       this.form.file.push(element)
    //     })
    //   }
    // },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        console.log(response.data)
        const ccc = response.data
        if (ccc.length > 1) {
          ccc.splice(0, 1)
        }
        ccc[0].fileType = 1
        const bbb = this.form.file
        bbb.asdasd = 122
        if (this.form.file[0]) {
          this.form.file = ccc
          this.$confirm('合同文件只能上传一个，是否替换？').then(req => {
            if (req === 'confirm') {
              this.form.file = ccc
            } else {
              this.form.file = bbb
            }
          }).catch(
            err => {
              console.log(this.form.file)
              this.form.file = bbb
              console.log(err)
            }
          )
        } else {
          this.form.file = ccc
        }
        console.log(this.form.file)
      }
    },
    handleSuccess2 (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(i => {
          i.fileType = 2
          this.file2.push(i)
        })
        console.log(this.file2)
        // const ccc = response.data
        // this.file2 = ccc
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }

}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/form.scss';
.mainform{
  .form {
     :deep(.col4) {
        padding: 10px 0px;
        margin: 0px auto;
    }
    :deep(.el-date-editor.el-input) {
      width: 48%;
    }
    :deep(.el-col-11){
      max-width: 49%;
    }
}
.action{
  margin-top: 35px;
}
}
.isreadonly{
  padding: 0;
  .table{
    .el-table{
      margin-top: 10px !important;
    }
  }
  :deep(.el-form-item__content){
    .el-upload--text{
      display: none;
    }
    .el-upload-list__item:first-child {
      margin-top: 5px;
    }
  }
}
</style>
